.ant-input {
  border-radius: 5px;
}

.ant-input-group-addon {
  border-radius: 5px;
}

.ant-btn-edit {
  border: 1px solid #131a54;
  border-radius: 5px;
  color: #1d1d1d;
}
.ant-tabs-tab{
  width: 184px !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #131A54;
  pointer-events: none;
}

.add-item-button{
  border: 1px solid #131A54;
  border-radius: 5px;
  color: #1D1D1D;
}

.job-creation-button {
  border: 1px solid #131A54;
  border-radius: 5px;
}

@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  #downloadTemplate-button{
    width: 190px !important;
  }
}
@media only screen and (max-width: 1113px) and (min-width: 992px) {
  #downloadTemplate-button{
    width: 170px !important;
  }
}
@media only screen and (max-width: 992px) and (min-width: 750px) {
  #downloadTemplate-button{
    width: 170px !important;
  }
  #upload-button{
    width: 115px !important;
  }
  #selectFile-button{
    width: 105px !important;
  }
  .addItem-button{
    width: 105px !important;
    background-color: #131a54;
  }
  .row-one{
    width: 900px !important;
  }
  .table-row{
    width: 900px !important;
  }
  .jobtable{
    width: 850px !important;
  }
}

