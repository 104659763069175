.make-logo {
  height: 100px;
  fill: #abafb7 !important;
  padding: 5px 5px;
}

.make-logo:hover {
  padding: 0px 0px;
  fill: #131a54 !important;
  cursor: pointer;
  filter: grayscale(100%) sepia(2%);
}

.logo-class {
  display: flex;
  justify-content: center;
  text-align: center;
}

.make-title {
  color: #abafb7 !important;
  padding-top: 20px;
}

.make-title:hover {
  color: #131a54 !important;
  padding-top: 20px;
  cursor: pointer;
}

.close-btn {
  font-size: 20px;
  color: #abafb7;
}

.close-btn:hover {
  font-size: 25px;
  color: #131a54;
}

.close-class {
  display: flex;
  justify-content: end;
}
