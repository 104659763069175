.job-type-tag {
  border-radius: 7px;
  padding-top: px;
  width: 92px;
  height: 22px;
  background-color: #27AE60;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  color: #FFFFFF;
}

.ant-card .ant-card-bordered {
  border: 0.75px solid rgba(171, 175, 183, 0.5);
  border-radius: 4px
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  width: 235px !important;
}