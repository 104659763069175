$isDragging: boolean;

.task-board-root {
  min-height: 0;
  height: 70vh;
  min-width: 1150px;
  max-width: 1400px;
  margin: auto;
}

.task-board-content {
  height: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
}

.task-board-col-root {
  user-select: none;
  flex: 1;
  background-color: #E6FDF8;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  > .ant-card-body {
    overflow: hidden;
    height: 100%;
    padding: 0;
  }
}

.droppable-root {
  height: 100%;
  overflow-y: auto;
}

.styled-card-history {
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 13px;
  height: 10rem;
}
.task-board-item-card-title {
  white-space: pre-wrap;
  margin-right: 0.25rem;
}
.ant-tabs-tab{
  width: 184px !important;
}
