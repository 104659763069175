.ant-btn-edit{
  border: 1px solid #131A54;
  border-radius: 5px;
  color: #1D1D1D;
}
.inventory-button {
  border: 1px solid #131A54;
  border-radius: 5px;
}
.goods-received-button {
  background-color: #131A54;
  color: white;
}

.add-item-button{
  border: 1px solid #131A54;
  border-radius: 5px;
  color: #1D1D1D;
}

.ant-drawer-title {
  font-size: 24px !important;
  font-weight: 700 !important;
}

.column {
  // width: 200px !important;
  // background-color: aqua !important;
  // border: 1px solid black;
}

@media screen and (max-width: 992px) {
  .column {
    flex: 10%;
  }
}

.col-title {
  background: #EEF4FF;
  padding: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}

.col-value {
  padding: 20px;
  margin-bottom: 5px;
  border: 1px solid #EEF4FF;
  font-size: 14px;
  font-weight: 400;
}

.tab-row {
  padding-top: 20px;
}

.ant-form-item-label > label {
  color:  rgba(64, 63, 63, 1) !important;
  font-family: 'Inter' !important;
  font-weight: 700;
  font-size: 16px;
  padding: 0px;
}

.ant-select-selection-serch-input {
  background-color: rgba(19, 26, 84, 1) !important;
  color: white !important;
  transition: 500ms;
}

.ant-drawer-title {
  font-size: 24px;
  font-family: 'Inter';
  font-weight: 600;
  padding-top: 20px;
  color: rgba(19, 26, 84, 1);
}

.sav-container {
  text-align: center;
}

.sav-btn {
  background-color: rgba(19, 26, 84, 1) !important;
  color: white !important;
  border-radius: 8px;
  margin-bottom: 20px;
}

.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  margin-top: 10px;
  padding: 10px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: rgba(19, 26, 84, 1);
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
  border-radius: 50%;
}

.ant-drawer-close:hover{
  color: white;
}
