.workshop-button {
  border-radius: 6px;
  height: 35px;
}
.add-workshop-button {
  background-color: #131A54;
  color: white;
  border: 2px solid #131A54;
}

.add-workshop-button:hover {
  color: #131A54;
  border: 2px solid #131A54;
}

.add-workshop-button:active {
  color: #131A54;
  border: 2px solid #131A54;
}

.wrk-input {
  margin-top: 14px;
  margin-bottom: 8px;
}