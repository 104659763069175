@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');
.ks_prof_info{
  border: none;
  text-align: center;
  width: 250px;
  .ant-card-body{
    padding: 1.5rem 0.5rem;
    span{
      svg{
        width: 80px;
        height: 80px;
        padding: 20px;
        border-radius: 80px;
        border: 2px solid gray;
        margin-bottom: 1rem;
      }
    }
  }
}

.Ks-layout {
  min-height: 100vh;
}

.ks_hdr {
  background: #D5DAE3;
  height: 9vh;
  padding: 0 1.5rem;
}

.ks_logo {
  
  
  &.ks_logo_img_header {
    max-height: 70px;
    margin-left: 13px;
    margin-top: 1px;
  }
}
.ka_btn_lgn {
  margin-top: 1vh;
}

.ks_logo {
  &.ks_logo_img_header_2 {
    max-height: 70px;
    max-width: 170px;
    margin-left: 13px;
    margin-top: 1px;
  }
  &.ks_logo_img_header_3 {
    max-height: 132px;
    max-width: 300px;
    margin-left: 13px;
    padding-top: 0px;
    // margin-top: 1px;
  }
  &.ks_logo_img_header_4 {
    max-height: 84px;
    max-width: auto;
    //margin-left: 3px;
    margin-right: -40px;
    margin-top: -40px;
  }
}

.Ks-logged-user {
  margin-right: 3px;
  display: flex;
  justify-content: right;
  align-items: center;
  .ks_btn_usr {
    height: 42px;
    margin-top: 3px;
    padding: 0;
  }
}

.ky_mn_btn {
  background: none;
  border: none;
  font-size: 18px;
  color: white;

  &:hover {
    background: none;
    border: none;
    color: rgba(0, 229, 179, 1);
    font-size: 20px;
  }
}

.ky_mn_btn-log {
  background: rgba(19, 26, 84, 1) !important ;
  color: white;
}
.welcome{
  margin-top:-12px;
  margin-bottom:18px;
}
.logo {
  height: 64px;
  padding: 5px;
  background: rgba(19, 26, 84, 1);
  font-family: montserrat;
  display: flex;
  justify-content: center;
  padding-top: 17px;
}

.lgout-pad{
  left: 1vh;
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 0;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.sider-menu {
  background: rgba(19, 26, 84, 1) !important;
  font-family: 'Inter';
}

.ks-content {
  margin: 9px 9px;
  padding: 24px !important;
  height: 89vh;

  @media (max-width: 767px) {
    max-width: calc(100% - 2rem);
    margin: auto;
    > Content {
      width: 100%;
    }
  }
}

.ks-menu-item {
  background: rgba(19, 26, 84, 1) !important;
}

.ant-menu-inline {
  background: rgba(19, 26, 84, 1) !important; 
}

.ks-header {
  background: rgba(19, 26, 84, 1) !important;
  color: white;
  padding: 0;
  margin: 0;
}
.language-button{
  margin-top: 18px;
}
.ky_btn_usr{
  margin-left: 22px;
  margin-top: 13px;
}
.notification-button{
  margin-left: 32px;
}
.ky_vtr {
  background: none;
  padding: 0;
  margin: 0;
  &:hover {
    color: rgba(0, 229, 179, 1);
    font-size: 20px;
  }
}

.ant-menu-item-selected { 
  background: rgba(0, 229, 179, 1) !important;
  color: black !important;
}

.ant-menu-item:hover {
  color: rgba(0, 229, 179, 1) !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #c7c7d0; 
  border-radius: 10px;
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background: #666b9d; 
}

.betaTag {
  font-size: 14px;
  width: 61px;
  text-align: center;
  border-radius: 4px;
  background: transparent;
  color: #f85050;
  border: 2px solid #fa4141;
}

.bt-tag-mark {
  float: right;
  //transform: rotate(45deg);
}

.kr-end {
  display: flex;
  font-size: 10px;
  justify-content: end;
  flex-direction: column;
  height: 20%;
  align-items: center;
  color: #fff;
}

.ant-col-1 {
  max-width: none;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary{
   border-left-color:transparent !important; 
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: transparent !important;
}

.ant-btn-icon-only:hover {
  color: rgba(0, 229, 179, 1)
}

.ant-btn-primary:focus {
  border-color:rgba(19, 26, 84, 1) ;
  background:rgba(19, 26, 84, 1);
}