.employee-button {
  border: 1px solid #131a54;
  border-radius: 5px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #131a54;
  border-right-width: 1px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #131a54;
  box-shadow: 0 0 0 1px #131a54;
  border-right-width: 1px !important;
  outline: 0px;
}
.ant-pagination-item-active {
  border-color: #131a54;
  background-color: #131a54;
}
.ant-pagination-item a {
  color: #abafb7;
  font-weight: bold;
}
.ant-pagination-item-link:hover {
  border-color: #131a54 !important;
  color: #131a54 !important;
}
.anticon-right:hover {
  color: #131a54;
}
.anticon-left:hover {
  color: #131a54;
}
.ant-pagination-item:hover {
  border-color: #131a54;
}
.ant-pagination-item:hover a {
  color: #131a54;
}
.ant-pagination-item-active a {
  color: white;
}
.ant-pagination-item-active:hover {
  border-color: #131a54;
  background-color: #131a54;
}
.ant-pagination-item-active a:hover {
  color: white;
}
.add-employee-button {
  background-color: #131a54;
  color: white;
}

.add-employee-button:hover {
  color: #131a54;
  border: 1px solid #131a54;
}

.add-employee-button:active {
  color: #131a54;
  border: 1px solid #131a54;
}

.steps {
  margin-bottom: 20px;
}

.steps-step {
  color: #131a54 !important;
}

.step-title {
  padding-bottom: 10px;
  color: #131a54 !important;
}

.col-odd {
  background: #eef4ff;
  border-radius: 4px;
  margin-top: 5px;
}

.col-even {
  background: #e6fdf8;
  border-radius: 4px;
  margin-top: 5px;
}

.step-col-title {
  padding: 12px;
  margin: 0px;
  font-size: 14px !important;
  color: #131a54;
}

.line-break {
  margin: 20px;
}

.file-upload {
  margin: 5px;
  display: block;
  text-align: right;
}

.file-upload-btn {
  margin: 8px;
  margin-bottom: 0px;
  border-radius: 4px;
  height: 35px;
}

.drawr-titl {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #131a54 !important;
  margin: 0px !important;
}

.tab-row {
  padding-top: 10px;
}