label.ant-form-item-required {
  font-size: 14px !important;
  color: #403F3F !important;
  font-weight: 400;
}

label {
  font-size: 14px !important;
  color: #403F3F !important;
  font-weight: 400;
}

.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #131A54;
}
.sav-btn {
  margin-left: 10px;
}

.subcollaps-header {
  color: #403F3F !important;
  font-weight: 500 !important;
}

.collapse-header{
  font-size: 18px;
  color: #131A54 !important;
  font-weight: bold;
}

.col-title {
  background: #EEF4FF;
  padding: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}

.col-value {
  padding: 20px;
  margin-bottom: 5px;
  border: 1px solid #EEF4FF;
  font-size: 14px;
  font-weight: 400;
}

.drawr-titl {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #131A54 !important;
  margin: 0px !important; 
}

.tab-row {
  padding-top: 10px;
}
.fuelType{
  width: 143px !important;
  height: 30px !important;
  border-radius: 5px !important;
  border-style: groove !important;
}
.insuranceName{
  width: 143px !important;
  height: 30px !important;
  border-radius: 5px !important;
  border-color: rgb(226, 213, 213)important;
}