.ks_lgn_vw {
  background-color: #D5DAE3;
}

.ks_frm {
  &.ks_frm_lgn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84vh;
    @media (max-width: 767px) {
      max-width: calc(100% - 2rem);
      margin: auto;
      > div {
        width: 100%;
      }
    }
  }
}

.ks_crd {
  &.ks_crd_lgn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F5F5 !important;
    border-radius: 8px;
    width: 500px;
    height: 500px;
    box-shadow: 0 2px 3px;
    margin: auto;

    &.ant-card {
      .ant-card-body{
        padding: 32px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 400px;
    }
  }
}

.ka_lbl_lgnfrm {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 25px;
}

.ks_int {
  &.ks_int_lgnfrm {
    box-shadow: 0 2px 4px gray;
    font-size: 16px !important;
    font-family: Inter;
    height: 5vh;
  }
}
input{
  &.ks_int{
    &.ks_int_lgnfrm{
      padding: 0 1rem;
    }
  }
}

.ka_btn_lgn {
  border-radius: 6px;
  font-size: 16px !important;
  font-family: Inter;
  background: #131A54 !important;
  height: 6vh;
  margin-top: 3vh;
}

button{
  &.ka_btn_lgn{
    padding: 0 10rem;
  }
}

.ks_logo {
  &.ks_logo_lgn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    height: 28vh;
  }
}

.ks_bk_frm_cnt{
  display: flex;
  justify-content: center;
  align-items: center;
}
