.ks_drp_vcl-ty {
  width: 100%;
  font-size: 16px !important;
  font-family: Inter;
  font-weight: normal !important;
}
.ant-btn-primary {
  background-color: #131a54;
  border-color: #131a54;
  border-radius: 8px;
}
.ant-btn-primary:hover {
  background-color: #131a54;
  border-color: #131a54;
  border-radius: 8px;
}
.ka_lbl_bkfrm {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 25px;
}
.button1{
  margin-left: 52px;
  margin-right: 20px;
}

.button2{
  margin-left: 50px;
  margin-right: 20px;
}

.selection_syl {
  font-size: 16px !important;
  font-family: Inter;
}


.ks_bk_frm {
  &.ks_frm_ad {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
    width: 200vh;
    @media (max-width: 767px) {
      max-width: calc(100% - 2rem);
      margin: auto;
      > div {
        width: 100%;
      }
    }
  }
}

.ks-frm-bk-content {
  background: #fff;
  padding: 20px;
  height: 88vh;
  width: 180vh;
}

.ks_int_bk {
  &.ks_int_bkfrm {
    font-size: 14px !important;
    font-family: Inter;
  }
}
input {
  &.ks_bk {
    &.ks_int_bkfrm {
      padding: 0 1rem;
    }
  }
}

.ka_btn_bk {
  border-radius: 6px;
  font-size: 16px !important;
  font-family: Inter;
  background: #131a54 !important;
  height: 6vh;
  margin-top: 2vh;
}

button {
  &.ka_btn_bk {
    padding: 0;
  }
}

.ks_lbl_titl {
  font-family: Inter;
  font-style: normal;
  font-size: 24px !important;
  color: #8c8c8c !important;
}
.collapse {
  background-color: #fff;
}
header {
  font-size: 15px;
}

.panel hr {
  width: 5px;
}
.ant-collapse-headerr {
  font-size: 18px;
  color: #131a54 !important;
  font-weight: bold;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #131a54 !important;
}

thead {
  background-color: #131a54;
}
.veh-details p {
  font-size: 15px;
  font-weight: bold;
  color: rgb(181, 193, 201);
}
.ant-table-thead .ant-table-cell {
  background-color: #131a54;
  color: #fff;
}

.ant-table-thead {
  border-radius: 5px;
}
.ant-table-cell {
  background-color: rgba(0, 229, 179, 0.1);
  margin-top: 3px;
}
.drawer-details-box {
  width: 181.25px;
  height: 70px;
  border: 0.5px solid #f0f0f0;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drawer-details-boxHeader {
  background-color: #eef4ff;
  font-weight: 500;
}
.drw-rw {
  padding-top: 3rem;
}
.drw-rw p {
  font-size: 20px;
  font-weight: 700;
  color: #131a54;
}
.apt-frm {
  padding-top: 2rem;
  text-align: center;
}
.sbt-btn {
  text-align: right;
  padding-top: 2rem;
}
.drw-btn {
  border: 2px solid #131a54;
  color: #131a54;
  width: 159px;
  height: 41px;
  border-radius: 8px;
}
.drw-btn:hover {
  color: #131a54;
  border: 2px solid #131a54;
}

.total {
  padding-left: 14px;
  font-size: 15px;
}

.ant-modal-body {
  padding: 2px;
}

.booking-appointment {
  top: 66px !important;
}
.ant-modal-title {
  font-size: 25px !important;
}
.first-row {
  margin-top: 8px;
  margin-bottom: 10px;
}
.second-row {
  margin-bottom: 10px;
}
.Button-row{
  margin-top: 55px !important;
  padding-bottom: 30px !important;
}
.total-column{
  text-align: right;
  font-weight: bold;
}
