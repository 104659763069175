.total-est {
  text-align: right !important;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: rgba(135, 130, 254, 0.25);
}
.ant-checkbox-inner {
  margin-top: 0px !important;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin-top: 25px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
  margin: 0;
  padding: 8px 16px;
  background: white;
   border: 1px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom: 4px solid #131A54;
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    width: 180px !important;
  }
}

