.dashboard-container{
  font-family: Inter !important;
}

.card-styles{
  margin-bottom: 15px;
  font-family: Inter !important;
}

.title-styles{
  color: #8C8C8C !important;
  font-weight: normal !important;
  font-family: Inter !important;
}

.sub-title-styles{
  font-weight: normal !important;
  font-family: Inter !important;
  font-size: 40px !important;
}
// @media (max-width: 1270px) and (min-width: 420px) {
// .ant-progress-circle-path{
//   width: 100px !important;
// }
// }
.progress-percentage{
  background-color: red;
}