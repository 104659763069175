$isDragging: boolean;

.task-board-root {
  min-height: 0;
  height: 70vh;
  min-width: 800px;
  max-width: 1400px;
  margin: auto;
}
.ant-checkbox-inner {
  margin-top: -16px;
}
.task-board-content {
  height: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
}

.task-board-col-root {
  user-select: none;
  flex: 1;
  background-color: #e6fdf8;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  > .ant-card-body {
    overflow: hidden;
    height: 100%;
    padding: 0;
  }
}

.droppable-root {
  height: 100%;
  overflow-y: auto;
}

.styled-card {
  margin: 0.5rem;
  padding: 0.5rem;
  height: 227px;
}

.task-board-item-card-title {
  white-space: pre-wrap;
  margin-right: 0.25rem;
}
.task-board-item-card-title-time {
  white-space: pre-wrap;
  display: flex;
  justify-content: right;
  margin-right: -10px;
}

.col-title {
  background: #eef4ff;
  padding: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}

.col-value {
  padding: 20px;
  margin-bottom: 5px;
  border: 1px solid #eef4ff;
  font-size: 14px;
  font-weight: 400;
}

.drawr-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #131a54 !important;
  margin: 0 !important;
}

.tab-row {
  padding-top: 20px;
}

.radio-button-status {
  background: transparent;
  border: none;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: none;
  border: none;
}

.ant-radio-group {
  border: none;
}

.auto-compete {
  width: 100%;
}

.status-icon {
  padding: 0;
}

.ant-statistic-content {
  font-size: 16px;
}
.jobStsTag-itm-crd-time {
  width: 121px;
  text-align: center;
  border-radius: 7px;
}
.jobStsTag-itm-crd {
  width: 80px;
  text-align: center;
  border-radius: 7px;
}

.ant-switch {
  background-color: #eb5757;
  background-image: none;
}

.ant-switch-checked {
  background-color: #27ae60;
}

.tableTopic {
  height: 27px;
  font-size: 20px;
  font-weight: bolder;
  color: #131a54;
  margin-top: 8px;
  margin-bottom: 8px;
}
.jobs-tab.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  width: 187px !important;
}
.ant-drawer-close{
  margin-top: 25px !important;
}
.jobStsTag {
  width: 112px;
  text-align: center;
  border-radius: 7px;
}


