.ant-btn-edit {
  border: 1px solid #131a54;
  border-radius: 5px;
  color: #1d1d1d;
}
.inventory-button {
  border: 1px solid #131a54;
  border-radius: 5px;
}
.goods-received-button {
  background-color: #131a54;
  color: white;
}

.add-item-button {
  border: 1px solid #131a54;
  border-radius: 5px;
  color: #1d1d1d;
}

.ant-drawer-title {
  font-size: 24px !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .column {
    flex: 10%;
  }
}

.col-title {
  background: #eef4ff;
  padding: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}

.col-value {
  padding: 20px;
  margin-bottom: 5px;
  border: 1px solid #eef4ff;
  font-size: 14px;
  font-weight: 400;
}

.tab-row {
  padding-top: 20px;
}

.ant-form-item-label > label {
  color: rgba(64, 63, 63, 1) !important;
  font-family: "Inter", serif !important;
  font-weight: 700;
  font-size: 16px;
  padding: 0;
}

.ant-select-selection-serch-input {
  background-color: rgba(19, 26, 84, 1) !important;
  color: white !important;
  transition: 500ms;
}

.ant-drawer-title {
  font-size: 24px;
  font-family: "Inter", serif;
  font-weight: 600;
  padding-top: 20px;
  color: rgba(19, 26, 84, 1);
}

.sav-btn {
  background-color: rgba(19, 26, 84, 1) !important;
  color: white !important;
  border-radius: 8px;
}